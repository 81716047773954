



function GeneralSettings () {

    return (
        <div>
            General settings
        </div>
    )
}

export default GeneralSettings;