
import { useState, useEffect, useRef } from 'react';
import "./nosactualites.css";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import Header from '../../header/Header';
import { API_actualites } from '../../services/api/actualitesServices';
import { Tag } from 'primereact/tag';

import EditorTagArticle from '../../../dashboard/tools/EditorTagArticle';
import EditorWindowArticle from '../../../dashboard/tools/EditorWindowArticle';

import { ls, ss } from '../../../utils/store';
import { Dialog } from 'primereact/dialog';

import ErrorComponent from '../../../dashboard/tools/ErrorComponent';

function NosActualite (props) {

    useEffect(() => {
        ss.set('window', 'actu');
    }, []);
  
    const [docHeight, setDocHeight] = useState(null);
    const [docWidth, setDocWidth] = useState(null);
    const [allTags, setAllTags] = useState([]);
    const [data, setData] = useState([]);
    const [detailArticleVisible, setDetailArticleVisible] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);
      
    useEffect(() => {
        try {
            setDocHeight(window.innerHeight - props.headerHeight);
            setDocWidth(window.innerWidth - 10);
        }
        catch(error){
            console.error(error);
        }
    }, [window.innerHeight]);

    useEffect(() => {
        try {
            //setData(nactusServ);
            const getData = async () => {
                try {
                    setData(await API_actualites.get_all());
                    setAllTags(await API_actualites.get_all_tags());
                    console.log(data);
                    console.log(allTags);
                }
                catch(error){
                    console.error(error);
                }
            }
            getData();   
        }
        catch(error){
            console.error(error);
        }
    }, []);

    const header = (d) => {
        try {
            if (d.img !== null && d.img !== undefined && d.img !== "null"){
                return (
                    <img alt="Card" src={d.img} />
                )
            }
            else {
                return (
                    <img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />
                )
            }
        }
        catch(error){
            console.error(error);
            return <ErrorComponent error={error} />
        }
    };

    const RenderTag = (props) => {
        try {
            if (allTags.length > 0){
                for (let i = 0; i < allTags.length; i++) {
                    console.log(allTags[i], props.tagid);
                    if (allTags[i].tag_id === props.tagid) {
                        console.log(allTags[i]);
                        return (
                            <Tag value={allTags[i].name} style={{backgroundColor: '#' + allTags[i].color}} />
                        )
                    }
                }
            }
            else {
                return (
                    null
                )
            }
        }
        catch(error){
            console.error(error);
            return <ErrorComponent error={error} />
        }
    };

    function handleOpenArticle(d) {
        try {
            setDetailArticleVisible(true);
            setSelectedDetail(d);
        }
        catch(error){
            console.error(error);
            return <ErrorComponent error={error} />
        }
    };

    const headerDetail = () => {
        try {
            if (selectedDetail !== null){
                if (selectedDetail.img === 'null'){
                    return <img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />
                }
                else {
                    return <img alt="Card" src={selectedDetail.img} />
                }
            }
        }
        catch(error){
            console.error(error);
            return <ErrorComponent error={error} />
        }
    };

    try {
        if (window.innerWidth < 1468){
            return (
                <EditorWindowArticle>
                <div className='overflow-x-hidden' style={{width: docWidth - 10, height: docHeight}}>
                    <Header setChildW={props.setChildW} setHeaderHeight={props.setHeaderHeight} />
                    <div className='grid place-items-center card bg-transparent'>
                        <h2 className='titleactu'>
                            L'actualité de la CPTS
                        </h2>
                        { 
                            data !== null ? (
                                <div className=''>
                                    <p className='maintext card bg-transparent'>
                                        <div>
                                            {data.mainText}
                                        </div>
                                    </p>
                                    <div className='grid grid-cols-1'>
                                        {
                                            data.data.map((d) => (
                                                <Card title={d.name} header={() => header(d)} className="m-10 h-[10%]">
                                                    <RenderTag tagid={d.tagid}/>
                                                </Card>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) :
                            (
                                null
                            )
                        }
                    </div>
                </div>
                </EditorWindowArticle>
            )
        }
        else {
            return (
                <EditorWindowArticle>
                <div className='overflow-x-hidden'>
                    <Header setChildW={props.setChildW} setHeaderHeight={props.setHeaderHeight} />
                    <div className='grid place-items-center card bg-transparent'>
                        <h2 className='titleactu'>
                            L'actualité de la CPTS
                        </h2>
                        { 
                            data !== null ? (
                                <div className=''>
                                    <p className='maintext card bg-transparent'>
                                        <div>
                                            {data.mainText}
                                        </div>
                                    </p>
                                    <div className='grid grid-cols-4'>
                                        {
                                            data.map((d) => (
                                                <EditorTagArticle dataObject={d} id={d.article_id} type="article" setDetailArticleVisible={setDetailArticleVisible}>
                                                    <div className='cursor-pointer' onClick={() => handleOpenArticle(d)}>
                                                        <Card title={d.name} header={() => header(d)} className="m-10 h-[10%]">
                                                            <RenderTag tagid={d.tagid}/>
                                                        </Card>
                                                    </div>
                                                </EditorTagArticle>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) :
                            (
                                null
                            )
                        }
                    </div>
                    <Dialog className='h-[80dvh] w-[60dvw]' visible={detailArticleVisible} onHide={() => setDetailArticleVisible(false)}>
                        {
                            selectedDetail !== null ? (
                                <Card title={selectedDetail.name} subTitle={selectedDetail.subtitle} header={headerDetail} className="md:w-25rem">
                                    <p className="m-0">
                                        <div dangerouslySetInnerHTML={{ __html: selectedDetail.description }}></div>
                                    </p>
                                </Card>
                            ) : 
                            (
                                null
                            )
                        }
                    </Dialog>
                </div>
                </EditorWindowArticle>
            )
        }
    }
    catch(error){
        console.error(error);
        return <ErrorComponent error={error} />
    }
}

export default NosActualite;